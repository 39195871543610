import React from 'react';

const fragmentedIndustry = () => {
  return (
    <div className="max-w-6xl mx-auto px-4">
      <h2 className="text-4xl font-light text-center mb-2">
        The circle is finally complete
      </h2>
        <h4 className="text-xl font-light text-center mb-5">
            Every other aspect of 401k plan management is scalable
        </h4>
            <h4 className='text-xl font-light text-center mb-2 italic mb-5'>now your fiduciary responsibilities are too.</h4>
    </div>
  );
};

export default fragmentedIndustry;